a {
  text-decoration: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-contents {
  display: contents !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-end {
  justify-content: end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.text-break {
  word-wrap: break-word;
}

.align-items-end {
  align-items: flex-end;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.flex-column {
  flex-direction: column;
}

.card {
  border: none;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.w-33 {
  width: 33% !important;
}

.view-container {
  background-color: var(--text-color);
  position: relative;
  height: 100vh;
  padding: 100px 1.5rem 1.5rem 1.5rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
}

.project-card {
  flex: 1 0 21%;
  border: solid 1px lightgray;
  background-color: var(--text-color);
  border-radius: 10px;
  max-width: calc(25% - 13px);
  min-height: 250px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 8px 1px rgb(34 41 47 / 10%);
}

.steps-action {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  align-items: flex-end;
  margin-bottom: 24px;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding: 10px 40px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #fafafaf6;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.input-detail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  margin: 0.5rem 0;
}

.description-container {
  padding: 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  color: var(--text-color);
  background-image: linear-gradient(
    to right bottom,
    #242045,
    #231f41,
    #211e3d,
    #201d3a,
    #1f1c36,
    #1f1c36,
    #1f1c36,
    #1f1c36,
    #201d3a,
    #211e3d,
    #231f41,
    #242045
  );
  width: 70%;
  left: 10%;
  box-shadow: 0px 5px 12px 0px rgb(0 0 0 / 51%);
}

.description-dashed {
  padding: 10px;
  border: dashed 1px #fff;
  border-radius: 8px;
}

.finish-step-container {
  width: 50%;
  flex-direction: column;
}

@media (min-width: 578px) {
  .d-lg-none {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .card-container {
    justify-content: center;
  }
  .project-card {
    min-width: 95%;
  }
  .steps-content {
    flex-direction: column;
  }
  .input-detail {
    width: 100%;
  }
  .description-container {
    width: 100%;
    left: 0;
  }
  .finish-step-container {
    width: 100% !important;
  }
}

@media (max-width: 578px) {
  .card-container {
    justify-content: center;
  }
  .project-card {
    min-width: 100%;
  }
}
