/* Google Font Link */

:root {
  --primary-color: #eabd56bf;
  --dark-color: #1d1b31;
  --background-color: #ffffff;
  --text-color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

.sidebar {
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: var(--text-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  display: flex;
  margin-top: 20px;
  padding-left: 0px;
  height: 100%;
  display: block !important;
}

.sidebar li {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
  margin: 8px;
  list-style: none;
  border-radius:13px;
  width: 200px;
}

.sidebar li .tooltips {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar li:hover .tooltips {
  opacity: 1;
  transition: all 0.4s ease;
  color: var(--primary-color);
}

.sidebar.open li .tooltips {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: var(--text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--dark-color);
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: var(--dark-color);
  color: var(--text-color);
}

.sidebar.open .bx-search:hover {
  background: var(--dark-color);
  color: var(--text-color);
}

.sidebar .bx-search:hover {
  background: var(--background-color);
  color: var(--primary-color);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--primary-color);
}

.sidebar li a:hover {
  background: var(--background-color);
}

.sidebar li a .links_name {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: var(--primary-color);
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: var(--dark-color);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color);
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--dark-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 0px;
  width: calc(100%);
  transition: all 0.5s ease;
  z-index: 2;
  padding: 0px;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--primary-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

/* HEADER */

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to right,
    #fbd378,
    #fcd16f,
    #fdcf66,
    #fdcd5d,
    #fecb54,
    #fbc850,
    #f8c54c,
    #f5c248,
    #eebd49,
    #e7b84b,
    #e0b44c,
    #d9af4d
  );
  color: var(--text-color);
  padding: 0px 10px;
  position: fixed;
  z-index: 9;
}

@media (max-width: 578px) {
  .sidebar li .tooltip {
    display: none;
    transition: all 0.5s ease;
  }
  .sidebar li {
    width: 50px;
  }
  .tooltips {
    display: none;
  }
  .sidebar.open {
    display: block;
    transition: all 0.5s ease;
  }

  .home-section {
    left: 0px !important;
    width: 100% !important;
  }
  .header-container #btn {
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
  }
}
