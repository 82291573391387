.paperContainer {
  width: 21cm;
  min-height: 29.2cm;
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 140px 48px;
  height: 100%;
}

.IDCardImageContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 40%;
}

.descContainer {
  width: 100%;
}

.header {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: 500;
  font-size: larger;
}

.idCardImages {
  width: 316px;
  height: 200px;
}

.printContainer {
  padding: 0px 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.largeFont {
  font-size: large;
}

.editorContainer {
  width: 40%;
}
.uploadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}
.label {
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .editorContainer {
    width: 100%;
  }
  .uploadContainer {
    padding: 0px;
  }

  .mobileDoc {
    overflow-x: scroll;
    margin-top: 10px;
    border-top: 1px solid lightgray;
  }
}
